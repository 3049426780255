<script setup lang="ts">
import type { EventCard } from "~/types/events";

const props = defineProps<{ event: EventCard; hideWebinarLink?: boolean }>();

const city = computed(() => props.event.location?.city);
</script>

<template>
  <CardsEventInfoCell
    v-if="city && event.eventType !== 'webinar'"
    icon="location-pin"
  >
    <span v-text="city" />
  </CardsEventInfoCell>
  <CardsEventInfoCell
    v-else-if="event.eventType === 'webinar' && !hideWebinarLink"
    icon="external-link"
  >
    <span>Webinar</span>
  </CardsEventInfoCell>
</template>
